import { HttpClient } from '@angular/common/http';
import { Observable, map, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiResponse } from './models/api.model';
import { SelectOption } from './models/select-option.model';
import { environment } from '@environments/environment';
import { MidCurrency } from './models/mid.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private readonly API_URL = environment.apiUrl;
  constructor(
    private http: HttpClient,
  ) { }

  getSelectOption(url: string): Observable<any[]> {
    return this.http.post<ApiResponse<any[]>>(`${this.API_URL}/${url}`, {})
      .pipe(map(res => res.Data));
  }
  getSelectOptionBy(url: string, obj: any): Observable<any[]> {
    return this.http.post<ApiResponse<any[]>>(`${this.API_URL}/${url}`, { ...obj })
      .pipe(map(res => res.Data));
  }

  getBankSelectOption(): Observable<SelectOption[]> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getbank`, {})
      .pipe(map(res => res.Data));
  }

  getInstallmentBankSelectOption(): Observable<SelectOption[]> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getinstallmentbank`, {})
      .pipe(map(res => res.Data));
  }

  getBranchSelectOption(): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getbranch`, {});
  }

  getCardBinGroupSelectOption(): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getcardbingroup`, {});
  }

  getCitySelectOption(CountryCode: string): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getcity`, { CountryCode });
  }

  getCountrySelectOption(): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getcountry`, {});
  }

  getCurrencySelectOption(): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getcurrency`, {});
  }

  getMccSelectOption(): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getmcc`, {});
  }

  getMerchantSelectOption(): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getmerchant`, {});
  }

  getPosByBranchSelectOption(BranchCode: string): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getposbybranch`, { BranchCode });
  }

  getStateSelectOption(CityCode: string): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getstate`, { CityCode });
  }

  getPOSByBank(branchCode: string): Observable<SelectOption[]> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getposbybranch`, { BranchCode: branchCode })
      .pipe(map(res => res.Data));
  }

  getMIDByBank(bankCode: string): Observable<MidCurrency[]> {
    return this.http.post<ApiResponse<MidCurrency[]>>(`${this.API_URL}/mdr/mdrmerchantgetlistmid`, { BankCode: bankCode })
      .pipe(map(res => res.Data));
  }

  getMIDByMerchantID(merchantID: string): Observable<MidCurrency[]> {
    return this.http.post<ApiResponse<MidCurrency[]>>(`${this.API_URL}/mdr/mdrmerchantgetlistmidbymerchantid`, { MerchantID: merchantID })
      .pipe(map(resp => resp.Data));
  }

  getStatusGroupSelectOption(groupID: number): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getstatusbygroup`, { GroupID: groupID });
  }

  public getCurrencyCodeByMID(requestData: GetCurrencyCodeByMIDRequest) {
    return this.http.post<ApiResponse<GetCurrencyCodeByMIDResponse>>(`${this.API_URL}/common/getcurrencycodebymid`, requestData);
  }

  getBeneficiaryMethodSelectOption(): Observable<SelectOption[]> {
    const beneficiaryMethods: SelectOption[] = [
      { Value: '1', Text: $localize`:@@BankAccount:Tài khoản ngân hàng` },
      { Value: '2', Text: $localize`:@@VNPAYWallet:Ví VNPAY` }
    ];
    return of(beneficiaryMethods);
  }
  getResultSelectOptions(): Observable<SelectOption[]> {
    const statusOptions: SelectOption[] = [
      { Value: 'SUCCESS', Text: $localize`:@@Success:Thành công` },
      { Value: 'FAIL', Text: $localize`:@@Failed:Thất bại` }
    ];
    return of(statusOptions);
  }

  getStatusSelectOption(): Observable<SelectOption[]> {
    const statusOptions: SelectOption[] = [
      { Value: 0, Text: $localize`:@@Active:Hoạt động` },
      { Value: 1, Text: $localize`:@@Inactive:Vô hiệu` }
    ];
    return of(statusOptions);
  }

  getGenderSelectOption(): Observable<SelectOption[]> {
    const genderOptions: SelectOption[] = [
      { Value: 'M', Text: $localize`:@@Male:Nam` },
      { Value: 'F', Text: $localize`:@@Female:Nữ` }
    ];
    return of(genderOptions);
  }

  getFeeTypeSelectOption(): Observable<SelectOption[]> {
    const feeType: SelectOption[] = [
      { Value: 1, Text: $localize`:@@FeeProgressive:Phí bậc thang` },
      { Value: 0, Text: $localize`:@@FeeCommon:Phí thông thường` }
    ];
    return of(feeType);
  }

  getMdrEffectSelectOption(): Observable<SelectOption[]> {
    const effect: SelectOption[] = [
      { Value: 0, Text: $localize`:@@Ineffective:Hết hiệu lực` },
      { Value: 1, Text: $localize`:@@Effective:Đang hiệu lực` },
      { Value: 2, Text: $localize`:@@WillEffective:Sắp hiệu lực`, }
    ];
    return of(effect);
  }

  getMdrBankEffectSelectOption(): Observable<SelectOption[]> {
    const effect: SelectOption[] = [
      { Value: 1, Text: $localize`:@@Disabled:Vô hiệu` },
      { Value: 2, Text: $localize`:@@Ineffective:Hết hiệu lực` },
      { Value: 3, Text: $localize`:@@Effective:Đang hiệu lực` },
      { Value: 4, Text: $localize`:@@WillEffective:Sắp hiệu lực`, }
    ];
    return of(effect);
  }

  getYesNoSelectOption(): Observable<SelectOption[]> {
    const opts: SelectOption[] = [
      { Value: '0', Text: $localize`:@@Message.No:Không` }, { Value: '1', Text: $localize`:@@Message.Yes:Có` }
    ];
    return of(opts);
  }

  getYesNoSelectOptions(): Observable<SelectOption[]> {
    const opts: SelectOption[] = [
      { Value: 0, Text: $localize`:@@No:Không` }, { Value: 1, Text: $localize`:@@Yes:Có` }
    ];
    return of(opts);
  }

  getProgressiveBySelectOption(): Observable<SelectOption[]> {
    const opts: SelectOption[] = [
      { Value: 0, Text: $localize`:@@Quantity:Số lượng` }, { Value: 1, Text: $localize`:@@Revenue:Doanh thu` },
    ];
    return of(opts);
  }

  getRefundStatusSelectOption(): Observable<ApiResponse<SelectOption[]>> {
    return this.http.post<ApiResponse<SelectOption[]>>(`${this.API_URL}/common/getrefundstatus`, {});
  }

  getSearchByTypeSelectOption(): Observable<SelectOption[]> {
    const opts: SelectOption[] = [
      { Value: 'SALE', Text: $localize`:@@DateTypeSale:Ngày giờ giao dịch:` },
      { Value: 'VOID', Text: $localize`:@@DateTypeVoid:Ngày giờ hủy:` }
    ]
    return of(opts);
  }

  getUserStatusSelectOptions(): Observable<SelectOption[]> {
    const opts: SelectOption[] = [
      { Value: 'ACTIVE', Text: $localize`:@@Active:Hoạt động` },
      { Value: 'LOCKED', Text: $localize`:@@Locked:Khóa` },
      { Value: 'DISABLED', Text: $localize`:@@Disabled:Vô hiệu` }
    ];
    return of(opts);
  }

  getMDRTypeSelectOptions(): Observable<SelectOption[]> {
    const opts: SelectOption[] = [
      { Value: 'MID', Text: 'MID' },
      { Value: 'MCC', Text: 'MCC' }
    ];
    return of(opts);
  }

  getTenorTypeSelectOptions(): Observable<SelectOption[]> {
    const opts: SelectOption[] = [
      { Value: 3, Text: $localize`:@@Tenor3:Kỳ 3 tháng` },
      { Value: 6, Text: $localize`:@@Tenor6:Kỳ 6 tháng` },
      { Value: 9, Text: $localize`:@@Tenor9:Kỳ 9 tháng` },
      { Value: 12, Text: $localize`:@@Tenor12:Kỳ 12 tháng` },
      { Value: 18, Text: $localize`:@@Tenor18:Kỳ 18 tháng` },
      { Value: 24, Text: $localize`:@@Tenor24:Kỳ 24 tháng` },
      { Value: 36, Text: $localize`:@@Tenor36:Kỳ 36 tháng` },
    ];
    return of(opts);
  }

  getDateTypeSelectOptions(): Observable<SelectOption[]> {
    const opts: SelectOption[] = [
      { Value: 0, Text: $localize`:@@DateTimeModify:Ngày cập nhật` },
      { Value: 1, Text: $localize`:@@DateCreate:Ngày tạo` },
      { Value: 2, Text: $localize`:@@DateSend:Ngày gửi` },
    ];
    return of(opts);
  }

  getPermissionsMasterData(): Observable<string[]> {
    return this.http.post<ApiResponse<string[]>>(`${this.API_URL}/common/getpermissionsmasterdata`, {})
    .pipe(map(response => response.Data));
  }
}

export interface GetCurrencyCodeByMIDRequest {
  MID: string;
}

export interface GetCurrencyCodeByMIDResponse {
  CurrencyCode: string;
}
